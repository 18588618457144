<template>
  <div class="wrap">
    <Loading v-show="isLoading"/>
    <van-list v-show="!isLoading">
      <!-- <van-cell v-for="(item,id) in list" :key="id" :title="item.title" is-link /> -->
      <van-col v-show="!isEmpty" span="22" offset="1" v-for="(item,id) in list" :key="id" style="margin-top:10px;border-radius:5px;">
        <van-cell is-link :center="true" @click="jumpTo(item)">
          <template #title>
            <span class="custom-title">{{item.wx_type | wxType}}</span>
            <span class="custom-date">{{item.apply_time}}</span>
            <van-tag color="#FFECE3" text-color="#FF6010" class="custom-status">{{item.pay_method | payMethod}}</van-tag>
          </template>
        </van-cell>
      </van-col>
      <van-empty v-show='isEmpty'
        class="custom-image"
        :image="emptyImg"
        description="暂无数据"
      />
    </van-list>
  </div>
</template>
<script>
import {myApply,getOpenId,querytype} from '@/server'
import { Dialog,Toast } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,domainUrl,getUrlParam,hidewxOption} from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      accessToken:this.$store.state.accessToken,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      list: [],
      isEmpty:false,
      emptyImg:require('@/assets/img/empty.png'),
      source:getUrlParam('flag'),
      isLoading:true
    };
  },
  filters: {
    //0:微信；1：支付宝  2：线下
    payMethod(s) {
      if(s == '0' || s == '1'){
        return '已支付';
      }else if(s == '2'){
        return '确认已付款';
      }else{
        return '--';
      }
    },
    //settle 提前结清,companypay 对公还款, datechange 还款日变更, basechange 手机号变更,cardchange 扣款卡变更
    wxType(s) {
      if(s == 'settle'){
        return '提前结清';
      }else if(s == 'companypay'){
        return '对公还款';
      }else if(s == 'datechange'){
        return '租后变更-还款日变更';
      }else if(s == 'basechange'){
        return '租后变更-手机号变更';
      }else if(s == 'cardchange'){
        return '租后变更-扣款卡变更';
      }else if(s == 'frtmoney'){
        return '前置保证金付款申请';
      }
    }
  },
  mounted(){
    sessionStorage.removeItem("origin");
    sessionStorage.removeItem("source");
    sessionStorage.removeItem("payInfoId");
    // sessionStorage.setItem('key', 'application2');
    // let that = this;
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // if(sessionStorage.getItem('key') == 'application2') {
    //     that.$router.push({ path: '/personalCenter' });
    //   }
    // }, false);
  },
  created(){
    //隐藏分享
    hidewxOption()
    this.getDetail()
  },
  methods: {
    getDetail(){
      if(!this.source){
        sessionStorage.setItem('menu_name','公户_我的申请-消息提醒')
        const querytypeparams = {
          card_id:sessionStorage.getItem("loginCard"),
          function_type:'menu',
          menu_name:'公户_我的申请-消息提醒'
        }
        querytype(querytypeparams);
      }
      const params = {
        openid:this.openId,
        access_token:this.accessToken,
        card_id:sessionStorage.getItem("loginCard")
      }
    myApply(params).then(res=>{
      this.list = res.filter(item => item.business_type!="03" && item.wx_type=="frtmoney");//01:公户，03:自然人
      this.isLoading = false;
      // if(res.wx_code == '0'){
        if(this.list.length == 0){
          this.isEmpty = true;
        }else{
          this.isEmpty = false;
          res.map(item => {
            if(item.wx_type == 'frtmoney'){
              item.pathUrl = '/enterprise/enterpriseAdvanceDeposit/paymentDetail?applyId='+item.apply_id+'&payMethod='+item.pay_method
            }
          });
        } 
      // }else{
      //   Toast(res.msg);
      // }
      
    })
  },
    jumpTo(item){
      // this.$store.commit('dataDetailList', item.detail);
      // :to="{ path: item.pathUrl, query: { dataDetail: item.detail }}"
      this.$router.push({ path: item.pathUrl });
      // window.open(item.pathUrl)
    },
  },
};
</script>
<style scoped>
.wrap{
    /* padding: 20px 0px;  */
    overflow: hidden;
    min-height: 100vh !important;
    background: #F8F8F8; 
  }
  .custom-title {
    display:block;
    /* vertical-align: middle; */
  }
  .custom-date {
    display:block;
    color: #999999;
    font-size: 12px;
  }
  .custom-status{
    font-size: 11px;
  }
</style>